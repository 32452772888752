<template>
    <div>
        <v-card class="card">
        <v-card-title>
          <h6>هزینه ها</h6>
        </v-card-title>
        <v-card-text>
          <v-row class="mb-4">
                <div class="d-flex mr-4">
                    <span class="">هزینه سفارشات :</span>
                    </div>
                    <div class="mr-auto ml-6">
                    <span class=" mr-1" v-text="cost.cost"></span>
                </div>
              </v-row>
              <!-- <v-row class="mb-4">
                <div class="d-flex mr-4">
                    <span >میزان تخفیف :</span>
                    </div>
                    <div class="mr-auto ml-6">
                    <span class="mr-1" v-text="cost.offer"></span>
                </div>
               </v-row> -->
            <v-row class="mb-4">
                <div class="d-flex mr-4">
                    <span class="">هزینه ارسال :</span>
                    </div>
                    <div class="mr-auto ml-6">
                    <span class=" mr-1" v-text="cost.post_price"></span>
                </div>
                </v-row>
                <v-divider class="my-4"></v-divider>
                <v-row class="mb-4">
                <div class="d-flex mr-4">
                    <span class="">مبلغ قابل پرداخت :</span>
                    </div>
                    <div class="mr-auto ml-6">
                    <span class=" mr-auto " v-text="cost.costToPay"></span>
                </div>
            </v-row>
            <v-row class="mb-4">
                <div class="d-flex mr-4">
                    <span>شیوه پرداخت :</span>
                </div>
            </v-row>
            <v-row class="mb-4" v-for="settlement in cost.settlements" :key="settlement.description">
                <div class="d-flex mr-4">
                    <span  v-text="settlement.description"></span>
                    </div>
                    <div class="mr-auto ml-6">
                    <span v-text="settlement.amount"></span>
                </div>
            </v-row>
        </v-card-text>
      </v-card> 
    </div>
</template>

<script>
export default {
    props: ['cost']
}
</script>